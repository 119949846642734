import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/arama/:text',
    name: 'search',
    component: () => import('../views/SubPages/SearchResult.vue'),
    props: (route) => {
      return { text: route.params.text }
    }
  },
  {
    path: '/iletisim',
    name: 'contact',
    component: () => import('../views/SubPages/Contact.vue')
  },
  {
    path: '/page/:id/:title',
    name: 'pages',
    component: () => import('../views/SubPages/DinamicContent.vue'),
    props: (route) => {
      return { id: route.params.id, title: route.params.title.split('/').pop() }
    }
  },
  {
    path: '/page/detail/:id/',
    name: 'content-page-detail',
    component: () => import('../views/SubPages/DinamicContent.vue'),
    props: (route) => {
      return { id: route.params.id, endpoint: route.name }
    }
  },

  // {
  //   path: '/icerik',
  //   name: 'content',
  //   component: () => import('../views/SubPages/Content.vue')
  // },
  {
    path: '/duyurular',
    name: 'announcements',
    component: () => import('../views/SubPages/Announcements.vue')
  },
  {
    path: '/duyuru/:id/:title',
    name: 'announcement-detail',
    component: () => import('../views/SubPages/AnnouncementDetail.vue'),
    props: (route) => {
      return { id: route.params.id, title: route.params.title.split('/').pop() }
    }
  },
  {
    path: '/foto-galeri',
    name: 'photo-gallery',
    component: () => import('../views/SubPages/PhotoGallery.vue'),
  },
  {
    path: '/resim/:id/:title',
    name: 'photo-gallery-detail',
    component: () => import('../views/SubPages/PhotoGalleryDetail.vue'),
    props: (route) => {
      return { id: route.params.id, title: route.params.title.split('/').pop() }
    }
  },
  {
    path: '/haberler',
    name: 'news',
    component: () => import('../views/SubPages/News.vue')
  },
  {
    path: '/haber/:id/:title',
    name: 'new-detail',
    component: () => import('../views/SubPages/NewDetail.vue'),
    props: (route) => {
      return { id: route.params.id, title: route.params.title.split('/').pop() }
    }
  },
  {
    path: '/video-galeri',
    name: 'video-gallery',
    component: () => import('../views/SubPages/VideoGallery.vue')
  },
  {
    path: '/video/:id/:title',
    name: 'video-gallery-detail',
    component: () => import('../views/SubPages/VideoGalleryDetail.vue'),
    props: (route) => {
      return { id: route.params.id, title: route.params.title.split('/').pop() }
    }
  },
  {
    path: '/kisiler/:id',
    name: 'persons',
    component: () => import('../views/SubPages/Persons.vue'),
    props: (route) => {
      return { id: route.params.id }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../views/SubPages/PageNotFound.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0, behavior: "smooth" };
  }
})

export default router
